import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface DialogData {
  typeClicked: string;
  status: string;
  rule:string;
}

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  title:string;
  header:string;
  subHeader: string;
  status: boolean;
  textUrl1: string;
  url1: string;
  hyperlink1: string;
  textUrl2: string;
  url2: string;
  hyperlink2: string;


  constructor(
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
     // console.log('data', this.data)
    }

  onNoClick(): void {
    this.dialogRef.close()
  }

  ngOnInit() {

    console.log('data:' ,this.data)
    if(this.data.rule===undefined){
      switch(this.data.typeClicked){
        case 'VACCINE':
          this.title='MODAL.VACCINE_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'TEST':
          this.title='MODAL.PCR_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'RECOVERY':
          this.title='MODAL.COVID_PASSED_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'PDF':
          this.title='MODAL.PDF_ERROR.TITLE';
          this.header='MODAL.PDF_ERROR.HEADER';
          break;
      }
    }
    else if(this.data.rule==='TECHNICAL'){
      switch(this.data.typeClicked){
        case 'VACCINE':
          this.title='MODAL.VACCINE_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'TEST':
          this.title='MODAL.PCR_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'RECOVERY':
          this.title='MODAL.COVID_PASSED_ERROR.TITLE';
          this.header='MODAL.TECHNICAL_ERROR.HEADER';
          break;
        case 'PDF':
          this.title='MODAL.PDF_ERROR.TITLE';
          this.header='MODAL.PDF_ERROR.HEADER';
          break;
      }
    }else if(this.data.status==='1002'){
      switch(this.data.typeClicked){
        case 'VACCINE':
          this.title='MODAL.VACCINE_OUT_CONDITIONS.TITLE';
          this.header='MODAL.VACCINE_OUT_CONDITIONS.HEADER';
          this.textUrl1 = 'MODAL.VACCINE_OUT_CONDITIONS.TEXT_HYPERLINK_1';
          this.hyperlink1 = 'MODAL.VACCINE_OUT_CONDITIONS.URL_HYPERLINK_1';
          this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/v/vacuna-covid-19/';
          break;
      }
    }else if(this.data.status==='1051'){
      switch(this.data.typeClicked){
        case 'VACCINE':
          this.title='MODAL.VACCINE_LESS.TITLE';
          this.header='MODAL.VACCINE_LESS.HEADER';
          break;
      }
    }else if(this.data.status==='1056'){
      switch(this.data.typeClicked){
        case 'TEST':
          this.title='MODAL.NOT_APROVED_EMA_TEST.TITLE';
          this.header='MODAL.NOT_APROVED_EMA_TEST.HEADER';
          this.textUrl1 = 'MODAL.NOT_APROVED_EMA_TEST.TEXT_HYPERLINK_1';
          this.hyperlink1 = 'MODAL.NOT_APROVED_EMA_TEST.URL_HYPERLINK_1';
          this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/covid-19/conviure/certificat-covid-digital/';
          break;
        case 'RECOVERY':
          this.title='MODAL.NOT_APROVED_EMA_RECOVERY.TITLE';
          this.header='MODAL.NOT_APROVED_EMA_RECOVERY.HEADER';
          this.textUrl1 = 'MODAL.NOT_APROVED_EMA_RECOVERY.TEXT_HYPERLINK_1';
          this.hyperlink1 = 'MODAL.NOT_APROVED_EMA_RECOVERY.URL_HYPERLINK_1';
          this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/covid-19/conviure/certificat-covid-digital/';
          break;
      }
    } else {
    switch(this.data.typeClicked){
      case 'VACCINE':
        this.title='MODAL.VACCINE_ERROR.TITLE';
        this.header='MODAL.VACCINE_ERROR.HEADER';
        break;
      case 'TEST':
        this.title='MODAL.PCR_ERROR.TITLE';
        this.header='MODAL.PCR_ERROR.HEADER';
        this.subHeader = 'MODAL.PCR_ERROR.SUB_HEADER';
        this.textUrl1 = 'MODAL.PCR_ERROR.TEXT_HYPERLINK_1';
        this.hyperlink1 = 'MODAL.PCR_ERROR.URL_HYPERLINK_1';
        this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/coronavirus-2019-ncov/ciutadania/certificat-covid-digital-ue/';
        this.textUrl2 = 'MODAL.PCR_ERROR.TEXT_HYPERLINK_2';
        this.hyperlink2 = 'MODAL.PCR_ERROR.URL_HYPERLINK_2';
        this.url2 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/coronavirus-2019-ncov/ciutadania/certificat-covid-digital-ue/centres-acreditats/'
        break;
      case 'RECOVERY':
        this.title='MODAL.COVID_PASSED_ERROR.TITLE';
        this.header='MODAL.COVID_PASSED_ERROR.HEADER';
        this.textUrl1 = 'MODAL.COVID_PASSED_ERROR.TEXT_HYPERLINK_1';
        this.hyperlink1 = 'MODAL.COVID_PASSED_ERROR.URL_HYPERLINK_1';
        this.url1 = 'https://canalsalut.gencat.cat/ca/salut-a-z/c/coronavirus-2019-ncov/ciutadania/certificat-covid-digital-ue/';
        break;
      case 'PDF':
        this.title='MODAL.PDF_ERROR.TITLE';
        this.header='MODAL.PDF_ERROR.HEADER';
        break;
      case 'DISABLED':
        this.title='MODAL.DISABLED_TITLE';
        this.header='MODAL.DISABLED_BODY'
        break;
    }
  }
    //console.log(this.body)
  }

}
