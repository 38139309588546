<div class="modal-header">
  <p class="modal-dialog" mat-dialog-title>{{title | translate}}</p>
  <button type="button" mat-dialog-close class="close" data-dismiss="modal" aria-label="Close">
  <span class="cross" aria-hidden="true">&times;</span>
  </button>
</div>
<div mat-dialog-content>
  <p class="modal-text">{{header | translate}} </p>
  <p class="modal-text">{{subHeader | translate}} </p>
</div>
<div  mat-dialog-content>
  <p class="modal-text">
    <span class="modal-text">{{textUrl1 | translate}}</span>
    <a class="modal-text" href="{{url1}}" target="_blank">{{hyperlink1 | translate}}</a>
    <span class="modal-text">{{textUrl2 | translate}}</span>
    <a class="modal-text" href="{{url2}}" target="_blank">{{hyperlink2 | translate}}</a>
  </p>
</div>
<div mat-dialog-content *ngIf='data.status!==null || data.status!==undefined'>
  <p class="modal-text">{{"MODAL.ERROR_CODE" | translate}}{{data.status}}</p>
</div>
