import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LanguageInfoService} from './language-info.service';
import {AuthenticationService} from '../../authentication/services/authentication.service';
import {UserRepresentation} from '../../authentication/resources/user-representation';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  constructor(private _translateService: TranslateService,
              private _languageInfoService: LanguageInfoService,
              private _authenticationService: AuthenticationService) {
    this.currentLanguage = new BehaviorSubject<string>(this.preferedUserLanguage ? this.preferedUserLanguage : this._translateService.getDefaultLang());
    this._authenticationService.getAuthenticatedUserRepresentation().subscribe((ur: UserRepresentation) => {
      if (ur && ur.lang) this.setCurrentLanguage(ur.lang);
      else this.setCurrentLanguage(this.currentLanguage.value);
    });

    this._languageInfoService.setAvailableLanguages(this.availableLanguages);
  }

  private availableLanguages: string[] = ["ca", "es"];
  private currentLanguage: BehaviorSubject<string>;

  getAvailableLanguages(): string[] {
    return this.availableLanguages;
  }

  getCurrentLanguage(): Observable<string> {
    return this.currentLanguage.asObservable();
  }

  setCurrentLanguage(lang: string) {
    this.preferedUserLanguage = lang;
    this._translateService.use(lang).subscribe(() => {
      this._languageInfoService.setCurrentLanguage(lang);
      this.currentLanguage.next(lang);
    });
  }

  private _preferedUserLanguage: string;
  get preferedUserLanguage(): string {
    const preferedLang = localStorage.getItem('prefered-user-language');
    if (!preferedLang || preferedLang == "undefined") return null;
    else return preferedLang;
  }
  set preferedUserLanguage(lang: string) {
    localStorage.setItem('prefered-user-language', lang);
  }
}
