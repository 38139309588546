import { SharedModule } from './../shared/shared.module';
import {NgModule} from '@angular/core';
import {LanguagesSelectorComponent} from './components/languages-selector.component/languages-selector.component';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    LanguagesSelectorComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    LanguagesSelectorComponent
  ]
})
export class LanguageModule { }
