import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

export function getCookie(name) {
  const cookies = document.cookie.split(';');
  const access_token_cookies = cookies.filter(cookie => cookie.trim().startsWith(name)).map(cookie => cookie.split('=')[1]);
  return access_token_cookies[1];
}

export function deleteCookie(name) {
  const cookie = getCookie(name);
  if (cookie) {
    if (!environment.deploy) document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    else {
      let domain = window.location.host;
      while (domain.indexOf(".") != domain.lastIndexOf(".")) {
        domain = domain.substring(domain.indexOf(".") + 1, domain.length);
      }
      document.cookie = `${name}=; Path=/; Domain=${domain}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
  }
}

//console.log('>>>>>>>>> Bootstrapping application...');

//const cip = getCookie('pacient_cip');
//console.log('......... Get-Cookie(pacient_cip): ' + cip);
//if (cip) {
//  sessionStorage.setItem('cip', cip);
//  deleteCookie('pacient_cip');
//}
// if (!localStorage.getItem('cip') && cip) localStorage.setItem('cip', cip);

//const accessTokenHC=''


const accessToken = getCookie('access_token');
//console.log('......... Get-Cookie(access_token): ' + accessToken);
if (accessToken) {
  sessionStorage.setItem('accessToken', accessToken);
  deleteCookie('access_token');
}
// } else {
//   //console.log('No hay ninguna cookie seteada')
//   //console.log('Se usara una hardcodeada')
//   sessionStorage.setItem('accessToken', accessTokenHC);
//   deleteCookie('access_token');
// }
// if (!localStorage.getItem('accessToken') && accessToken) localStorage.setItem('accessToken', accessToken);

// const refreshToken= ''
//const refreshToken = getCookie('refresh_token');
//console.log('......... Get-Cookie(refreshToken): ' + refreshToken);
// if (refreshToken) {
//   sessionStorage.setItem('refreshToken', refreshToken);
//   deleteCookie('refresh_token');
// }
// if (!localStorage.getItem('refreshToken') && refreshToken) localStorage.setItem('refreshToken', refreshToken);

platformBrowserDynamic().bootstrapModule(AppModule);
