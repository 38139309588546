import {NgModule} from '@angular/core';
import {MaterialModule} from '../material/material.module';
import {TranslateModule} from '@ngx-translate/core';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [
    ErrorModalComponent
      ],
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule.forChild()
  ],
  exports: [
    ErrorModalComponent,
  ],
  entryComponents: [
  ]
})
export class SharedModule { }
